<template>
    <div>
        <div class="table-responsive col-sm-12 col-lg-12">
            <table class="table table-bordered text-center">
                <thead>
                    <tr>
                        <th style="width: 15%; max-width: 15%;"></th>
                        <th v-for="Crane in CranesOps" :style="Crane.CraneAlias=='TOTAL'?'width:'+CraneWidth+';background:#ededed;':'width:'+CraneWidth">
                            {{ Crane.CraneAlias }}
                        </th>
                    </tr>
                    <tr>
                        <th>
                            <div style="height: 190px;" class="d-flex align-content-center flex-wrap">
                                <div class="d-flex align-items-center justify-content-center">
                                    <span class="circle mr-1" style="background: #002060;"></span>{{ $t('label.Operations') }}
                                </div>
                                <div class="d-flex align-items-center justify-content-center">
                                    <span class="circle mr-1" style="background: #f59546;"></span>{{ $t('label.Inactivity') }}
                                </div>
                            </div>
                        </th>
                        <th v-for="Crane in CranesOps" :style="Crane.CraneAlias=='TOTAL'?'background:#ededed;':''">
                            <simpleDonut 
                                v-if="SeriesArray(Crane)[0]!=0||SeriesArray(Crane)[1]!=0"
                                :dataLabels="true"
                                :Series="SeriesArray(Crane)" 
                                :Colors="ColorArray" 
                                :Categories="LabelsArray"
                                :Tooltip="Tooltip(Crane)"
                                Height="190px"
                                :isDonut="false"
                            />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="text-left">{{ $t('label.dateStartOperations') }}</td>
                        <td v-for="Crane in CranesOps" :style="Crane.CraneAlias=='TOTAL'?'background:#ededed;':''">
                            {{ Crane.BeginOpsDate?DateCrane(Crane.BeginOpsDate):'' }}
                        </td>
                    </tr>
                    <tr>
                        <td class="text-left">{{ $t('label.dateEndOperations') }}</td>
                        <td v-for="Crane in CranesOps" :style="Crane.CraneAlias=='TOTAL'?'background:#ededed;':''">
                            {{ Crane.LastOpsDate?DateCrane(Crane.LastOpsDate):'' }}
                        </td>
                    </tr>
                    <tr>
                        <td class="text-left">{{ $t('label.CraneHrs') }}</td>
                        <td v-for="Crane in CranesOps" :style="Crane.CraneAlias=='TOTAL'?'background:#ededed;':''">
                            {{ Crane.CraneHoursDs?Crane.CraneHoursDs:'00:00' }}
                        </td>
                    </tr>
                    <tr>
                        <td class="text-left">{{ $t('label.HoursDelays') }}</td>
                        <td v-for="Crane in CranesOps" :style="Crane.CraneAlias=='TOTAL'?'background:#ededed;':''">
                            {{ Crane.DelaysHoursDs?Crane.DelaysHoursDs:'00:00' }}
                        </td>
                    </tr>
                    <tr>
                        <td class="text-left">{{ $t('label.movements') }}</td>
                        <td v-for="Crane in CranesOps" :style="Crane.CraneAlias=='TOTAL'?'background:#ededed;':''">
                            {{ Crane.CantMovs?Crane.CantMovs:'0' }}
                        </td>
                    </tr>
                    <tr>
                        <td class="text-left">{{ $t('label.NetHrs') }}</td>
                        <td v-for="Crane in CranesOps" :style="Crane.CraneAlias=='TOTAL'?'background:#ededed;':''">
                            {{ Crane.NetHoursDs?Crane.NetHoursDs:'00:00' }}
                        </td>
                    </tr>
                    <tr>
                        <td class="text-left">{{ $t('label.MovsXHrNet') }}</td>
                        <td v-for="Crane in CranesOps" :style="Crane.CraneAlias=='TOTAL'?'background:#ededed;':''">
                            {{ Crane.NetMovsXHour?Crane.NetMovsXHour:'0' }}
                        </td>
                    </tr>
                    <tr>
                        <td class="text-left">{{ $t('label.MovsXHrGross') }}</td>
                        <td v-for="Crane in CranesOps" :style="Crane.CraneAlias=='TOTAL'?'background:#ededed;':''">
                            {{ Crane.GrossMovsXHour?Crane.GrossMovsXHour:'0' }}
                        </td>
                    </tr>
                    <tr>
                        <td class="text-left">{{ $t('label.inactivityTime') }}</td>
                        <td v-for="Crane in CranesOps" :style="Crane.CraneAlias=='TOTAL'?'background:#ededed;':''">
                            {{ Crane.InactiveHoursDs?Crane.InactiveHoursDs:'00:00' }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { DateFormater } from '@/_helpers/funciones';
import simpleDonut from '@/pages/situacion-operativa-actual/components/simpleDonut';

function data() {
    return {
        CranesOps: [],
    }
}
//methods
function DateCrane(date) {
    return DateFormater.formatDateTimeWithSlash(date);
}

function SeriesArray(Crane){
    let data = [];
    if (Crane) {
        data.push(Crane?.CraneMinutes?Crane.CraneMinutes:0);
        data.push(Crane?.InactiveMinutes?Crane.InactiveMinutes:0);
    }
    return data;
}
//computed
function CraneWidth() {
    let length = (85/this.CranesOps.length).toFixed(2);
    return length+'%';
}

function ColorArray(){
    let data = ['#002060', '#f59546'];
    return data;   
}

function LabelsArray(){
    let data = [this.$t('label.Operations'), this.$t('label.Inactivity')];
    return data;
}

function Tooltip(CranesOps) {
    return{
        y: {
            formatter: function (val) {
                let tooltip = ''
                if (val==CranesOps.CraneMinutes) {
                    tooltip = CranesOps.CraneHoursDs;
                }
                if (val==CranesOps.InactiveMinutes) {
                    tooltip = CranesOps.InactiveHoursDs;
                }
                return tooltip;
            }
        }
    }
}

export default {
    name:"time-in-port",
    data,
    mixins: [],
    props:{
        VisitCranesOpsJson:{
            type: Array,
            default: function () {
                return [] 
            },
        }
    },
    methods:{
        DateCrane,
        SeriesArray,
        Tooltip,
    },
    components:{
        simpleDonut,
    },
    computed:{
        CraneWidth,
        LabelsArray,
        ColorArray,
        ...mapState({
            VisitId: state => state.Management.VisitId,
            CarruselTab: state => state.Management.CarruselTab,
            bulkCarrier: state => state.Management.bulkCarrier,
        })
    },
    watch:{
        CarruselTab: function (val, oldVal) {
            if (val==(this.bulkCarrier==1?8:5)) {
                this.CranesOps = this.VisitCranesOpsJson;
            }
            if (oldVal==(this.bulkCarrier==1?8:5)) {
                this.CranesOps = [];
            }
        }
    }
}
</script>
<style scoped>
.circle{
    height: 15px;
    width: 15px;
    background: #666666;
    display: inline-block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
</style>