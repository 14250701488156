<template>
    <div>
        <div class="table-responsive col-sm-12 col-lg-12">
            <table class="table table-bordered text-center">
                <thead>
                    <tr>
                        <th rowspan="3" style="width: 18%;"></th>
                        <th colspan="2" style="background: #ededed;">{{ $t('label.planned') }}</th>
                        <th colspan="2" style="background: #ededed;">{{ $t('label.executed') }}</th>
                        <th rowspan="2" style="width: 8%;">%</th>
                    </tr>
                    <tr>
                        <th style="color: #ff0a30;width: 18.5%;">{{ $t('label.full') }}</th>
                        <th style="color: #91a849;width: 18.5%;">{{ $t('label.empty') }}</th>
                        <th style="color: #ff0a30;width: 18.5%;">{{ $t('label.full') }}</th>
                        <th style="color: #91a849;width: 18.5%;">{{ $t('label.empty') }}</th>
                    </tr>
                    <tr>
                        <th>
                            <simpleDonut
                                :dataLabels="true"
                                :Series="SeriesArray(this.ExecutedJson, 'Full')" 
                                :Colors="ColorArray" 
                                :Categories="LabelsArray"
                                :Tooltip="Tooltip"
                                Height="210px"
                                :isDonut="false"
                            />
                        </th>
                        <th>
                            <simpleDonut
                                :dataLabels="true"
                                :Series="SeriesArray(this.ExecutedJson, 'Empty')" 
                                :Colors="ColorArray" 
                                :Categories="LabelsArray"
                                :Tooltip="Tooltip"
                                Height="210px"
                                :isDonut="false"
                            />
                        </th>
                        <th>
                            <simpleDonut
                                :dataLabels="true"
                                :Series="SeriesArray(this.PlannedJson, 'Full')" 
                                :Colors="ColorArray" 
                                :Categories="LabelsArray"
                                :Tooltip="Tooltip"
                                Height="210px"
                                :isDonut="false"
                            />
                        </th>
                        <th>
                            <simpleDonut
                                :dataLabels="true"
                                :Series="SeriesArray(this.PlannedJson, 'Empty')" 
                                :Colors="ColorArray" 
                                :Categories="LabelsArray"
                                :Tooltip="Tooltip"
                                Height="210px"
                                :isDonut="false"
                            />
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody class="text-center">
                    <tr>
                        <td class="text-left text-white" style="background: #ec7c30;">{{ $t('label.DISCHARGE') }}</td>
                        <td>{{ Validation(PlannedVsExecuted[0]?.PlannedJson[0].CantFull) }}</td>
                        <td>{{ Validation(PlannedVsExecuted[0]?.PlannedJson[0].CantEmpty) }}</td>
                        <td>{{ Validation(PlannedVsExecuted[0]?.ExecutedJson[0].CantFull) }}</td>
                        <td>{{ Validation(PlannedVsExecuted[0]?.ExecutedJson[0].CantEmpty) }}</td>
                        <td>{{ Validation(PlannedVsExecuted[0]?.Percentage) }}</td>
                    </tr>
                    <tr>
                        <td class="text-left text-white" style="background: #4471c4;">{{ $t('label.LOAD') }}</td>
                        <td>{{ Validation(PlannedVsExecuted[1]?.PlannedJson[0].CantFull) }}</td>
                        <td>{{ Validation(PlannedVsExecuted[1]?.PlannedJson[0].CantEmpty) }}</td>
                        <td>{{ Validation(PlannedVsExecuted[1]?.ExecutedJson[0].CantFull) }}</td>
                        <td>{{ Validation(PlannedVsExecuted[1]?.ExecutedJson[0].CantEmpty) }}</td>
                        <td>{{ Validation(PlannedVsExecuted[1]?.Percentage) }}</td>
                    </tr>
                    <tr>
                        <td class="text-left text-white" style="background: #0f5c57;">{{ $t('label.shipToShip') }}</td>
                        <td>{{ Validation(PlannedVsExecuted[2]?.PlannedJson[0].CantFull) }}</td>
                        <td>{{ Validation(PlannedVsExecuted[2]?.PlannedJson[0].CantEmpty) }}</td>
                        <td>{{ Validation(PlannedVsExecuted[2]?.ExecutedJson[0].CantFull) }}</td>
                        <td>{{ Validation(PlannedVsExecuted[2]?.ExecutedJson[0].CantEmpty) }}</td>
                        <td>{{ Validation(PlannedVsExecuted[2]?.Percentage) }}</td>
                    </tr>
                    <tr>
                        <td class="text-left" style="background: #d2fbad;">{{ $t('label.shipLandShip') }}</td>
                        <td>{{ Validation(PlannedVsExecuted[3]?.PlannedJson[0].CantFull) }}</td>
                        <td>{{ Validation(PlannedVsExecuted[3]?.PlannedJson[0].CantEmpty) }}</td>
                        <td>{{ Validation(PlannedVsExecuted[3]?.ExecutedJson[0].CantFull) }}</td>
                        <td>{{ Validation(PlannedVsExecuted[3]?.ExecutedJson[0].CantEmpty) }}</td>
                        <td>{{ Validation(PlannedVsExecuted[3]?.Percentage) }}</td>
                    </tr>
                    <tr>
                        <td class="text-left" style="background: #ff5b5b;">{{ $t('label.CANCELADO') }}</td>
                        <td>{{ Validation(PlannedVsExecuted[4]?.PlannedJson[0].CantFull) }}</td>
                        <td>{{ Validation(PlannedVsExecuted[4]?.PlannedJson[0].CantEmpty) }}</td>
                        <td>{{ Validation(PlannedVsExecuted[4]?.ExecutedJson[0].CantFull) }}</td>
                        <td>{{ Validation(PlannedVsExecuted[4]?.ExecutedJson[0].CantEmpty) }}</td>
                        <td>{{ Validation(PlannedVsExecuted[4]?.Percentage) }}</td>
                    </tr>
                    <tr>
                        <th>TOTAL</th>
                        <th>{{ Validation(PlannedVsExecuted[5]?.PlannedJson[0].CantFull) }}</th>
                        <th>{{ Validation(PlannedVsExecuted[5]?.PlannedJson[0].CantEmpty) }}</th>
                        <th>{{ Validation(PlannedVsExecuted[5]?.ExecutedJson[0].CantFull) }}</th>
                        <th>{{ Validation(PlannedVsExecuted[5]?.ExecutedJson[0].CantEmpty) }}</th>
                        <th>{{ Validation(PlannedVsExecuted[5]?.Percentage) }}</th>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { DateFormater } from '@/_helpers/funciones';
import simpleDonut from '@/pages/situacion-operativa-actual/components/simpleDonut';

function data() {
    return {
        PlannedVsExecuted: [],
        ExecutedJson: [],
        PlannedJson: [],
    }
}
//methods
function Validation(val) {
    if (val) {
        return val;
    } else {
        return 0;
    }
}

function SeriesArray(Val, Condition){
    let data = [];
    if (Val.length!=0&&Condition=='Full') {
        data.push(Val[0].CantFull);
        data.push(Val[1].CantFull);
    };
    if (Val.length!=0&&Condition=='Empty') {
        data.push(Val[0].CantEmpty);
        data.push(Val[1].CantEmpty);
    };
    return data;
}
//computed
function ColorArray(){
    let data = ['#ec7c30', '#4471c4'];
    return data;   
}

function LabelsArray(){
    let data = [this.$t('label.DISCHARGE'), this.$t('label.LOAD')];
    return data;
}

function Tooltip() {
    return{
        y: {
            formatter: function (val) {
                return val;
            }
        }
    }
}

export default {
    name:"time-in-port",
    data,
    mixins: [],
    props:{
        MovsPlannedVsExecutedJson:{
            type: Array,
            default: function () {
                return [] 
            },
        }
    },
    methods:{
        Validation,
        SeriesArray,
    },
    components:{
        simpleDonut,
    },
    computed:{
        ColorArray,
        LabelsArray,
        Tooltip,
        ...mapState({
            VisitId: state => state.Management.VisitId,
            CarruselTab: state => state.Management.CarruselTab,
        })
    },
    watch:{
        CarruselTab: function (val, oldVal) {
            if (val==6) {
                this.PlannedVsExecuted = this.MovsPlannedVsExecutedJson;
                if (this.MovsPlannedVsExecutedJson.length!=0) {
                    this.MovsPlannedVsExecutedJson.map(item => {
                        this.ExecutedJson.push(item.ExecutedJson[0])
                        this.PlannedJson.push(item.PlannedJson[0])
                    })
                }
            };
            if (oldVal==6) {
                this.PlannedVsExecuted = [];
                this.ExecutedJson = [];
                this.PlannedJson = [];
            }
        }
    }
}
</script>
<style scoped>
.circle{
    height: 15px;
    width: 15px;
    background: #666666;
    display: inline-block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
.lapse-table{
width: 100%;
border-collapse: collapse;
}
table.lapse-table tr:last-child td {
    border-bottom: none;
}
table.lapse-table tr:first-child th {
    border-top: none !important;
}
table.lapse-table th{
border-left: none !important;
border-bottom: none;
}
table.lapse-table th:last-child {
border-right: none !important;
}
table.lapse-table td:first-child {
border-left: none !important;
border-right: none !important;
}
table.lapse-table td:last-child {
border-right: none !important;
}
</style>