<template>
    <main>
      <loading-overlay
        :active="Loading"
        :is-full-page="true"
        loader="bars"
      />
      <CCollapse :show="!collapseManagement">
        <CCard>
          <CCardHeader class="text-center bg-dark text-white">
            <b>{{$t('label.Management')}}</b>
          </CCardHeader>
          <CCardBody>
            <div class="col-sm-12">
              <div class="row justify-content-between mt-2">
                <div class="col-sm-12 tex-left">
                  <div class="row text-left">
                    <div class="col-auto text-left">
                      <CSelect
                        :options="months"
                        :value="Month"
                        @change="selectMonth($event)"
                      />
                    </div>
                    <div class="col-auto text-left">
                      <CSelect
                        :options="computedYears"
                        :value="year"
                        @change="selectYear($event)"
                      />
                    </div>
                    <div class="col-auto text-left">
                      <p style="margin-top: 0.5rem">{{$t('label.service')}} :</p>
                    </div>
                    <div class="col-3 text-left">
                      <v-select
                        :placeholder="$t('label.select')+$t('label.service')"
                        :options="computedServiceList"
                        class="select-services--vesselIndex"
                        v-model="ServiceModel"
                        @input="selectService"
                      />
                    </div>
                    <div class="col-auto text-left">
                      <p style="margin-top: 0.5rem">{{$t('label.vessel')}} :</p>
                    </div>
                    <div class="col-3 text-left">
                      <v-select
                        :placeholder="$t('label.select')+$t('label.vessel')"
                        :options="computedVesselList"
                        class="select-services--vesselIndex"
                        v-model="VesselModel"
                        @input="selectVessel"
                      >
                        <template slot="option" slot-scope="option">
                          <div class="row justify-content-between">
                            <div class="col">
                              {{ option.label }}
                            </div>
                            <div class="col text-right">
                              <CImg
                                :src="option.icon"
                                width="40"
                                height="25"
                                class="ml-5"
                              />
                            </div>
                          </div>
                        </template>
                      </v-select>
                    </div>
                    <div class="col-auto">
                      <CButton
                        size="sm"
                        square
                        color="edit"
                        class="d-flex align-items-center"
                        v-c-tooltip="{
                          content: $t('label.reset')+$t('label.table'),
                          placement: 'top',
                        }"
                        @click="refresCalendar"
                      >
                        <CIcon name="cil-loop-circular" />
                      </CButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CCol sm="12">
              <full-calendar
                :config.sync="configCallendar"
                :events="computedEvents"
                :header="header"
                @event-drop="eventDrop($event)"
                @event-mouseover="eventMouse($event)"
                :drop="drop"
                @event-selected="eventSelectedCalendar($event)"
                class="calendar-itinerary"
                :key="callendarKey"
                ref="calendar"
                id="calendar"
              />
            </CCol>
          </CCardBody>
        </CCard>
      </CCollapse>
      <CCollapse :show="collapseManagement">
        <Management/>
      </CCollapse>
    </main>
</template>
<script>
import { FullCalendar } from 'vue-full-calendar';
import 'fullcalendar/dist/fullcalendar.css';
import { mapState } from 'vuex';
import { DateFormater } from '@/_helpers/funciones';
import DataStorage from '@/_helpers/localStorage';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import GeneralMixin from '@/_mixins/general';
import Management from './Management-index.vue'
import moment from 'moment';
  
function data() {
    return {
      items: [],
      Loading: false,
  
      collapse: false,
      events: [],
      config: {
        locale: this.$i18n.locale,
        defaultView: 'month',
        editable:false,
        eventResize: function(info) {
          alert(info.event.title + " end is now " + info.event.end.toISOString());
          if (!confirm("is this okay?")) {
            info.revert();
          }
        }
      },
      header: {
        left: "today",
        center: "title",
        right: "listYear,month,agendaWeek"
      },
      VesselId: '',
      ServiceId: '',
      Month: '',
      year: '',
      ListYears: [],
      ServiceModel:null,
      VesselModel:null,
      callendarKey: 0,
      drop: false,
      yearsSelected: [],
      itemsBuque:[],
      itemsServices:[]
    };
}
  
//methods
async function selectMonth(event) {
    this.Month = event.target.value;
    this.filterActive = true;
    await this.mountedItinerarioList();
}
  
async function selectYear(event) {
    this.year = event.target.value;
    this.filterActive = true;
    await this.mountedItinerarioList();
}
  
async function checkServicePortList(id) {
    this.OrigitPortId = '';
    this.Loading = true;
  
    await this.$http
      .get(`ServicePort-list?ServiceId=${id}`)
      .then((response) => {
        response.data.data.map((item) => {
          if (item.PortId === this.branch.PortId) {
            this.OrigitPortId = item.ServicePortId;
          }
        });
        if (this.OrigitPortId === '') {
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: 'El puerto destino no corresponde al puerto de la sucursal',
            type: 'error',
          });
        }
      })
      .finally(() => {
        this.Loading = false;
      })
      .catch((e) => {
        this.Loading = false;
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: e,
          type: 'error',
        });
      });
}
  
async function mountedMont() {
    let date = new Date();
    let month = parseInt(date.getMonth());
    this.Month = month + 1;
    this.year = parseInt(date.getFullYear());
    await this.mountedYear();
    await this.mountedVesselList();
    await this.mountedServiceList();
    await this.mountedItinerarioList();
}
  
async function mountedYear(){
    this.Loading = true;
  
    this.$http
      .get('Year-list')
        .then((response) => (this.yearsSelected = response.data.data))
        .finally(() => {
          this.Loading = false;
        })
        .catch((e) => {
          this.Loading = false;
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: e,
            type: 'error',
          });
        });
}
  
async function mountedVesselList() {
    this.Loading = true;
    let ruta = 'Vessel-list';
      this.$http
        .get(ruta)
        .then((response) => (this.itemsBuque = response.data.data))
        .finally(() => {
          this.Loading = false;
        })
        .catch((e) => {
          this.Loading = false;
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: e,
            type: 'error',
          });
        });
}
  
async function mountedServiceList() {
    this.Loading = true;
  
    this.$http
      .get('Service-list?CompanyBranchId='+this.user.BranchJson[0].CompanyBranchId)
      .then((response) => (this.itemsServices = response.data.data))
      .finally(() => {
        this.Loading = false;
      })
      .catch((e) => {
        this.Loading = false;
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: e,
          type: 'error',
        });
      });
}
  
function rangoFecha(yearsSelected) {
    let beginningYear = yearsSelected.length!=0?yearsSelected[0].Year:2022;
    let EndYear = yearsSelected.length!=0?yearsSelected[yearsSelected.length-1].Year:2022;
    var anioAtras = new Date(beginningYear,0,1);
    var anioSiguiente = new Date(EndYear,11,31);
    return {
      start: anioAtras,
      end: anioSiguiente
    };
}
  
async function selectService(payload) {
    if (payload !== null) {
      this.ServiceModel = payload.label;
      this.ServiceId = payload.value;
      this.filterActive = true;
      await this.mountedItinerarioList();
    } else {
      this.ServiceModel = '';
      this.ServiceId = '';
      await this.mountedItinerarioList();
    }
}
  
async function selectVessel(payload){
    if (payload !== null) {
      this.VesselModel = payload.label;
      this.VesselId = payload.value;
      this.filterActive = true;
      await this.mountedItinerarioList();
    } else {
      this.VesselModel = '';
      this.VesselId = '';
      await this.mountedItinerarioList();
    }
}
  
async function refresCalendar() {
    await this.mountedMont();
    this.VesselModel = '';
    this.VesselId = '';
    this.ServiceModel = '';
    this.ServiceId = '';
    this.filterActive = false;
    this.drop = false;
    this.$store.state.Management.itinerarySelected = {};
    this.$store.state.Management.collapseManagement = false;
}
  
async function eventDrop(event) {
    const arrayIntinerary = await this.events.filter(
      (element) => element.VisitId === event.id
    );
    await this.checkServicePortList(arrayIntinerary[0].ServiceId);
}
  
async function eventMouse(event){
    tippy("#"+event.classNames, {
      getReferenceClientRect: null,
      content: event.description,
      animation: 'scale',
      theme: 'bluesimple',
    });
}
  
function eventSelectedCalendar(event) {
    const arrayIntinerary = this.events.filter(
      (element) => element.VisitId === event.id
    );
    this.$store.state.Management.VisitId = arrayIntinerary[0].VisitId ? arrayIntinerary[0].VisitId : 0;
    this.$store.state.Management.itinerarySelected = arrayIntinerary[0];
    if (arrayIntinerary[0].TpVesselId == '2A719797-70DB-437B-B55D-58F4177CB87B'){
      this.$store.state.Management.bulkCarrier = 1;
    }else if (arrayIntinerary[0].TpVesselId == process.env.VUE_APP_BULK_VESSEL_ID){
      this.$store.state.Management.bulkCarrier = 2;
    }
    this.$store.state.Management.collapseManagement = true;
}
  
async function mountedItinerarioList() {
    this.Loading = true;
  
    this.config = await localStorage.getItem('user');
    this.config = JSON.parse(this.config);
    if (Object.keys(this.config).length !== 0) {
      this.CompanyBranchId = this.config.BranchJson[0].CompanyBranchId;
    }
    this.$http
      .get(
        `VisitItineraryManagement-list-by-year-month?CompanyBranchId=${this.CompanyBranchId}&VesselId=${this.VesselId}&ServiceId=${this.ServiceId}&Year=${this.year}&Month=${this.Month}`
      )
      .then((response) => {
        this.events = response.data.data;
        let mes = this.Month;
        if (mes < 10) mes = '0'+this.Month;
        this.$refs.calendar.fireMethod('gotoDate', this.year+'-'+mes+'-01');
      })
      .finally(() => {
        this.Loading = false;
      })
      .catch((e) => {
        this.Loading = false;
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: e,
          type: 'error',
        });
      });
}
  
//computed
function configCallendar() {
    let local = this.$i18n.locale;
    let sendCAllendarConfig = {
      locale: local,
      defaultView: 'month',
      editable:false,
      //events: this.events,
      eventRender: function(event, element){
        element[0].id = event.classNames;
      },
      validRange: rangoFecha(this.yearsSelected)
    };
    return sendCAllendarConfig;
}
  
function months(){
    return [
      { label: this.$t('label.january'), value: 1 },
      { label: this.$t('label.february'), value: 2 },
      { label: this.$t('label.march'), value: 3 },
      { label: this.$t('label.april'), value: 4 },
      { label: this.$t('label.may'), value: 5 },
      { label: this.$t('label.june'), value: 6 },
      { label: this.$t('label.july'), value: 7 },
      { label: this.$t('label.august'), value: 8 },
      { label: this.$t('label.september'), value: 9 },
      { label: this.$t('label.octuber'), value: 10 },
      { label: this.$t('label.november'), value: 11 },
      { label: this.$t('label.december'), value: 12 },
    ];
}
  
function computedVesselList() {
    if (this.itemsBuque.length > 0) {
      return this.itemsBuque.map((item) => {
        return {
          label: item.VesselName,
          value: item.VesselId,
          icon:
            ((item.Icon !== '') && (item.Icon !== undefined) && (item.Icon !== null))
              ? `${this.$store.getters["connection/getBase"]}${item.Icon}`
              : `${this.$store.getters["connection/getBase"]}${item.VesselFlagRoute}`,
        };
      });
    }
}
  
function computedServiceList() {
    if (this.itemsServices.length > 0) {
      return this.itemsServices.map((item) => {
        return {
          label: item.ServiceCode,
          value: item.ServiceId,
        };
      });
    }
}
  
function computedYears() {
    if(this.yearsSelected.length === 0){
      return [{
        value: parseInt(this.year), 
        label: '',
      }];
    }else{
      var chart = [];
      this.yearsSelected.map(function(e){
        chart.push({
          value: e.Year, 
          label: '',
        })
      })
      return chart;
    }
}
  
function computedEvents() {
    if (this.events.length > 0) {
      let local = DataStorage.getLocale();
      return this.events.map((item,index) => {
        let DepartureCalendar = moment(item.DepartureCalendar.replace('T', ' ').replace('Z', '')).format('YYYY-MM-DD HH:mm')
        if (Number.parseInt(moment(DepartureCalendar).format('HH')) < 9) {
          DepartureCalendar = moment(DepartureCalendar).add(12, 'h').format('YYYY-MM-DD HH:mm');
        }
        return {
          id: item.VisitId,
          title: `${item.VesselName}, ${item.Voyage}, ${item.LabelArrival} ${DateFormater.formatDateTimeWithSlash(item.Arrival)} - ${item.LabelDeparture} ${DateFormater.formatDateTimeWithSlash(item.Departure)} `,
          start: item.Arrival,
          end: DepartureCalendar,
          backgroundColor: item.Color,
          textColor: '#F0F0F0',
          editable: false,
          classNames: "cambio-especial"+index,
          borderColor: item.Color,
          description: item.VisitId ? (local == 'es'? item.VisitStatusEs : item.VisitStatusEn) : (local == 'es'? item.ValueStatusDs : item.ValueStatusDsEn)
        };
      });
    }
}
  
export default {
    name: 'calendar-index',
    components: { tippy, FullCalendar, Management },
    beforeDestroy(){
      this.$store.state.Management.collapseManagement = false;
      this.$store.state.Management.CarruselTab = 0;
    },
    data,
    mounted(){
      this.$nextTick(async () => {
        if(!this.collapseManagement){
          await this.mountedMont();
        }
      });
    },
    mixins: [GeneralMixin],
    methods: {
      mountedItinerarioList,
      checkServicePortList,
      selectMonth,
      selectYear,
      mountedMont,
      mountedYear,
      mountedVesselList,
      mountedServiceList,
      rangoFecha,
      selectService,
      selectVessel,
      refresCalendar,
      eventDrop,
      eventMouse,
      eventSelectedCalendar,
    },
    computed: {
      configCallendar,
      computedYears,
      months,
      computedVesselList,
      computedServiceList,
      computedEvents,
      ...mapState({
        branch: state => state.auth.branch,
        user: state => state.auth.user,
        collapseManagement: state => state.Management.collapseManagement,
      }),
    },
    watch: {
      configCallendar: function(nuevo) {  
        this.callendarKey+= 1;
        this.mountedItinerarioList();
      },
      collapseManagement: function (val) {
        if (!val) {
          this.mountedItinerarioList();
        }
      }
    }
};
</script>